
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        





































































































































/* stylelint-disable declaration-no-important, max-nesting-depth, no-descending-specificity */
.combo,
[class*='combo--'] {
  overflow: hidden;
  width: 100vw;
  max-width: 86rem;
  min-height: 100%;
  padding: 5rem 2rem 0;
  background: $white;

  ::v-deep {
    .cards-grid {
      padding-top: 7rem !important;
      padding-right: 0;
      padding-left: 0;
    }

    .cards-grid__list__item--full--reverse {
      flex-direction: row !important;
    }

    .cards-grid__list__item--full {
      min-height: 25rem !important;
    }

    .cards-grid__list__item-outer {
      position: relative;

      & + .cards-grid__list__item-outer {
        margin-top: $spacing * 4 !important;

        &::before {
          @include center-x;

          content: '+';
          top: 0;
          display: block;
          font-family: $ff-alt;
          font-weight: 900;
          transform: translate(-50%, -5.5rem) scale(2);
        }
      }

      &:not(:first-child) {
        margin-top: $spacing * 2;
      }
    }

    .card__picture__logo {
      top: 50% !important;
      left: 50% !important;
      width: 100%;
      max-width: 10rem;
      transform: translate(-50%, -50%);
    }

    .card__infos {
      padding: $spacing $spacing * 2;
    }

    .card__infos__title {
      font-size: 2.4rem;
    }

    .wysiwyg {
      font-size: 1.8rem;
    }

    .combo-card__price-inner {
      margin-bottom: 4rem;
    }
  }

  .card {
    flex-direction: row !important;
  }

  @include mq(m) {
    padding: $spacing * 3 $spacing * 4;
  }
}

.combo-inner {
  text-align: center;
}

.combo__title {
  @extend %fw-medium;

  strong {
    @extend %fw-black;
  }
}

.combo__icon {
  height: 3rem;
  margin-bottom: $spacing * 0.5;
  fill: $c-pink-medium;

  @include mq(m) {
    height: 5rem;
  }
}

.combo__highlight {
  margin-top: 3rem;
  margin-bottom: 4rem;
}

.combo__grid {
  text-align: left;
}

.combo__link-outer {
  display: block;
  margin-top: $spacing;
}

// Price box
.combo-card__price-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: $spacing / 2 $spacing;
}

.combo-card__price__from,
.combo-card__price__prev {
  @extend %fw-medium;
  @extend %text-uppercase;

  color: $gray-medium;
  font-size: 0.8rem;
}

.combo-card__price__from {
  color: $c-blue-pale;
  font-family: $ff-alt;
  font-size: 0.9rem;
  letter-spacing: 0.5px;
}

.combo-card__price__prev {
  position: relative;
  display: table;
  color: $black;
  font-size: 2rem;
}

.combo-card__price__prev--crossed {
  position: relative;
  display: inline-block;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 100%;
    height: 1px;
    background-color: $black;
    transform: translate(-50%, -50%);
  }
}

.combo-card__price__price {
  display: flex;
  color: $c-pink-medium;
  font-size: 5rem;
  line-height: 1;

  & > small {
    display: flex;
    flex-direction: column;
    margin: 0.3rem 0 0 0.3rem;
    font-size: 1.7rem;

    & :last-child {
      padding-top: 0.2rem;
      color: $gray-medium;
    }
  }
}

.combo-card__price__option {
  max-width: 15rem;
}

.combo-card__price__period {
  ::v-deep em {
    @extend %fw-bold;

    color: $c-orange;
    font-style: normal;
  }
}
